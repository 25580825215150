var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container clearfix"},[_vm._m(0),_vm._m(1),_c('article',{staticClass:"webList"},[_c('section',{staticClass:"webList-container"},[_vm._m(2),_vm._m(3),_vm._m(4),_c('el-popover',{staticClass:"popover",staticStyle:{"border-radius":"20px !important"},attrs:{"placement":"top-end","title":"","width":"220","trigger":"hover"}},[_c('div',{staticClass:"popper"},[_c('span',[_c('a',{attrs:{"href":"http://ncme.chinagas.com.cn/","target":"_blank"}},[_vm._v("认证中心")])]),_c('el-divider',{attrs:{"direction":"vertical"}}),_c('span',[_c('a',{staticClass:"special",attrs:{"href":"http://www.chinagas.com.cn:85/admin/registe/index.html","target":"_blank"}},[_vm._v("网上办公")])])],1),_c('a',{staticClass:"special link",attrs:{"slot":"reference"},slot:"reference"},[_c('img',{staticClass:"fr-img4",attrs:{"src":require("@/assets/PortalPage/4.png"),"alt":""}})])])],1),_vm._m(5)]),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"info-container"},[_c('img',{staticClass:"info-img",attrs:{"src":require("@/assets/PortalPage/info.png"),"alt":""}}),_c('div',{staticClass:"info"},[_vm._v("www.chinagas.com.cn")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"web-container"},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/PortalPage/icon1.png"),"alt":""}}),_c('span',[_vm._v("中国燃气具行业权威官方网站")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"link",attrs:{"href":"http://www.chinagas.com.cn:9100","target":"_blank"}},[_c('img',{staticClass:"fr-img1",attrs:{"src":require("@/assets/PortalPage/1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"link",attrs:{"href":"http://www.chinagas.com.cn:9200","target":"_blank"}},[_c('img',{staticClass:"fr-img2",attrs:{"src":require("@/assets/PortalPage/2.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"link",attrs:{"href":"http://ncme.chinagas.com.cn/","target":"_blank"}},[_c('img',{staticClass:"fr-img3",attrs:{"src":require("@/assets/PortalPage/3.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"Qrcode-container"},[_c('div',{staticClass:"left-words"},[_c('img',{attrs:{"src":require("@/assets/PortalPage/scan.png"),"alt":""}}),_c('span',[_vm._v(" 添加并关注公众号，更快获取相关资讯。 ")])]),_c('div',{staticClass:"right-Qrcode"},[_c('div',{staticClass:"first"},[_c('img',{attrs:{"src":require("@/assets/PortalPage/leftQrcode.png"),"alt":""}}),_c('span',[_vm._v("燃气学会供热及应用平台")])]),_c('div',{staticClass:"second"},[_c('img',{attrs:{"src":require("@/assets/PortalPage/leftQrcode.png"),"alt":""}}),_c('span',[_vm._v("燃气用具公共资讯网")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"foot-container"},[_c('div',[_c('div',{staticClass:"copy"},[_vm._v(" 版权所有 国家燃气用具质量检验检测中心 津ICP备13005273号-4 ")]),_c('a',{staticClass:"police",attrs:{"href":"https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=12010302001213","target":"_blank"}},[_c('div',[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/PortalPage/icon2.png"),"alt":""}}),_c('span',[_vm._v("津公网安备 12010302001213号")])])])])])
}]

export { render, staticRenderFns }