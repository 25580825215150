<template>
  <div>
    <div class="container clearfix">
      <header class="info-container">
        <img src="@/assets/PortalPage/info.png" alt="" class="info-img" />
        <div class="info">www.chinagas.com.cn</div>
      </header>
      <header class="web-container">
        <img src="@/assets/PortalPage/icon1.png" alt="" class="img" />
        <span>中国燃气具行业权威官方网站</span>
      </header>
      <article class="webList">
        <section class="webList-container">
          <a class="link" href="http://www.chinagas.com.cn:9100" target="_blank">
            <img src="@/assets/PortalPage/1.png" alt="" class="fr-img1"/>
          </a>
          <a class="link" href="http://www.chinagas.com.cn:9200"  target="_blank">
            <img src="@/assets/PortalPage/2.png" alt="" class="fr-img2"/>
          </a>
          <a href="http://ncme.chinagas.com.cn/" target="_blank" class="link">
            <img src="@/assets/PortalPage/3.png" alt="" class="fr-img3"/>
          </a>
          <el-popover
            placement="top-end"
            title=""
            width="220"
            trigger="hover"
            class="popover"
            style="border-radius: 20px !important"
          >
            <div class="popper">
              <span>
                <a href="http://ncme.chinagas.com.cn/" target="_blank"
                  >认证中心</a
                >
              </span>
              <el-divider direction="vertical"></el-divider>
              <span>
                <a
                  href="http://www.chinagas.com.cn:85/admin/registe/index.html"
                  target="_blank"
                  class="special"
                  >网上办公</a
                >
              </span>
            </div>
            <a class="special link" slot="reference">
              <img src="@/assets/PortalPage/4.png" alt="" class="fr-img4"/>
            </a>
          </el-popover>
        </section>
        <section class="Qrcode-container">
          <div class="left-words">
            <img src="@/assets/PortalPage/scan.png" alt="" />
            <span> 添加并关注公众号，更快获取相关资讯。 </span>
          </div>
          <div class="right-Qrcode">
            <div class="first">
              <img src="@/assets/PortalPage/leftQrcode.png" alt="" />
              <span>燃气学会供热及应用平台</span>
            </div>
            <div class="second">
              <img src="@/assets/PortalPage/leftQrcode.png" alt="" />
              <span>燃气用具公共资讯网</span>
            </div>
          </div>
        </section>
      </article>
      <footer class="foot-container">
        <div>
          <div class="copy">
          版权所有 国家燃气用具质量检验检测中心 津ICP备13005273号-4
        </div>
        <a
          href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=12010302001213"
          class="police"
          target="_blank"
        >
          <div>
            <img src="@/assets/PortalPage/icon2.png" alt="" class="img" />
            <span>津公网安备 12010302001213号</span>
          </div>
        </a>
        </div>

      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "portalPage",
  //动态metaInfo
  metaInfo() {
    return {
      title: "燃气用具公共信息网",
      meta: [
        {
          name: "测试name",
          content: "测试content",
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.clearfix {
  content: "";
  clear: both;
  display: table;
}
.container {
  width: 100%;
  position: relative;
  height: 100vh;
  width: 100%;
  background: url(../../assets/PortalPage/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  // background-position: ;
  .info-container {
    position: absolute;
    left: 66px;
    top: 97px;
    .info-img {
      width: 767px;
      height: 208px;
    }
    .info {
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 30px;
      margin-left: 65px;
      margin-top: -50px;
    }
  }
  .web-container {
    height: 40px;
    position: absolute;
    top: 40px;
    right: 56px;
    display: flex;
    .img {
      width: 34px;
      height: 34px;
    }
    span {
      margin-left: 10px;
      display: inline-block;
      font-size: 22px;
      font-family: FZLanTingHeiS-H-GB;
      font-weight: 600;
      color: #ffb96c;
      line-height: 10px;
      height: 34px;
      line-height: 34px;
    }
  }
  .webList {
    position: absolute;
    top: 427px;
    width: 100%;
    .webList-container {
      margin: 0 130px;
      height: 100px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      .link {
        display: inline-block;
        width: 383px;
        height: 147px;
        background: #eff7fd;
        box-shadow: 0px 8px 12px 1px rgba(3, 26, 68, 0.5);
        border-radius: 20px;
        cursor: pointer;
        img {
          position: relative;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .Qrcode-container {
      width: 100%;
      height: 164px;
      background: rgb(30, 64, 127);
      margin-top: 150px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      .left-words {
        flex: 1;
        margin-left: 209px;
        display: flex;
        img {
          width: 48px;
          height: 48px;
        }
        span {
          margin-left: 17px;
          display: inline-block;
          height: 48px;
          line-height: 48px;
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: 800;
          color: #ffffff;
        }
      }
      .right-Qrcode {
        flex: 1;
        display: flex;
        .first,
        .second {
          display: flex;
          img {
            width: 118px;
            height: 118px;
          }
          span {
            margin-left: 16px;
            display: inline-block;
            height: 118px;
            line-height: 118px;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 800;
            color: #ffffff;
          }
        }
        .first {
          span {
            margin-right: 51px;
          }
        }
      }
    }
  }
  .foot-container {
    position: absolute;
    top: 90vh;
    height: 90px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .copy {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 16px;
    }
    .police {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      margin-top: 20px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      text-decoration: none;
      color: #989292;
      line-height: 24px;
      span {
        margin-left: 10px;
      }
    }
  }
}
.popper {
  text-align: center;
  padding: 20px !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  letter-spacing: 2px;
  a {
    text-decoration: none;
    font-size: 17px;
    color: rgb(255, 156, 60);
    font-weight: 600;
  }
  .special {
    text-decoration: none;
    color: rgb(27, 114, 232);
    font-size: 17px;
    font-weight: 600;
  }
}

@media screen and (max-width : 1366px) {
  .container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: url(../../assets/PortalPage/background.png);
  background-repeat: no-repeat;
  background-size: cover;
  // background-position: ;
  .info-container {
    position: absolute;
    left: 21px;
    top: 40px;
    .info-img {
      width: 675px;
      height: 183px;
    }
    .info {
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 30px;
      margin-left: 65px;
      margin-top: -50px;
    }
  }
  .web-container {
    height: 40px;
    position: absolute;
    top: 40px;
    right: 56px;
    display: flex;
    .img {
      width: 34px;
      height: 34px;
    }
    span {
      margin-left: 10px;
      display: inline-block;
      font-size: 22px;
      font-family: FZLanTingHeiS-H-GB;
      font-weight: 600;
      color: #ffb96c;
      line-height: 10px;
      height: 34px;
      line-height: 34px;
    }
  }
  .webList {
    position: absolute;
    top: 300px;
    width: 100%;
    .fr-img1{
      width: 260px;
      height: 37px;
    }
     .fr-img2{
     width: 185px;
     height: 66px;
    }
     .fr-img3{
     width: 170;
     height: 68px;
    }
     .fr-img4{
    width: 187px;
    height: 34px;
    }
    .webList-container {
      margin: 0 63px;
      height: 100px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-top: -50px;
      .link {
        display: inline-block;
        width: 286px;
        height: 110px;
        background: #eff7fd;
        box-shadow: 0px 8px 12px 1px rgba(3, 26, 68, 0.5);
        border-radius: 20px;
        cursor: pointer;
        img {
          position: relative;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .Qrcode-container {
      width: 100%;
      height: 116.8px;
      background: rgb(30, 64, 127);
      margin-top: 65px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      .left-words {
        flex: 1;
        margin-left: 148px;
        display: flex;
        img {
          width: 37px;
          height: 37px;
        }
        span {
          margin-left: 17px;
          display: inline-block;
          height: 37px;
          line-height: 37px;
          font-size: 21px;
          font-family: PingFang SC;
          font-weight: 800;
          color: #ffffff;
        }
      }
      .right-Qrcode {
        flex: 1;
        display: flex;
        .first,
        .second {
          display: flex;
          img {
            width: 84px;
            height: 84px;
          }
          span {
            margin-left: 16px;
            display: inline-block;
            height: 84px;
            line-height: 84px;
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: 800;
            color: #ffffff;
          }
        }
        .first {
          span {
            margin-right: 51px;
          }
        }
      }
    }
  }
  .foot-container {
    position: absolute;
    top: 85vh;
    // height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .copy {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 16px;
    }
    .police {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      margin-top: 20px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      text-decoration: none;
      color: #989292;
      line-height: 24px;
      span {
        margin-left: 10px;
      }
    }
  }
}
.popper {
  text-align: center;
  padding: 10px !important;
  display: flex;
  justify-content: space-around;
  align-items: center;
  letter-spacing: 2px;
  a {
    text-decoration: none;
    font-size: 20px;
    color: rgb(255, 156, 60);
    font-weight: 600;
  }
  .special {
    text-decoration: none;
    color: rgb(27, 114, 232);
    font-size: 20px;
    font-weight: 600;
  }
}

}
</style>
